import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import OneSignal from 'onesignal-cordova-plugin';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
	constructor( private platform: Platform ) {
	  platform.ready().then(() => { 
		  OneSignalInit();
	  });
  
	   // Call this function when your app starts
	  function OneSignalInit(): void {
		  // Uncomment to set OneSignal device logging to VERBOSE  
		  // OneSignal.setLogLevel(6, 0);
  
		  // NOTE: Update the setAppId value below with your OneSignal AppId.
		  OneSignal.setAppId("70390da9-9e25-4d7e-91db-e73f177d0bbe");
		  //android d5eadcca-f4c0-4b07-b38c-603e5ad8f5b1
		  //ios 7957ade9-72f9-46c7-bc94-37849c37ce90
		  OneSignal.setNotificationOpenedHandler(function(jsonData) {
			console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
		  });
  
		  // iOS - Prompts the user for notification permissions.
		  //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 6) to better communicate to your users what notifications they will get.
		  OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
			console.log("User accepted notifications: " + accepted);
		  });
  
		  if (window.localStorage.getItem('uid') !=null){
			  let externalUserId = window.localStorage.getItem('uid'); // You will supply the external user id to the OneSignal SDK
			  OneSignal.setExternalUserId(externalUserId);
		  }
	  } 	  	  
	}
  
  }