import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-booking-success',
  templateUrl: './booking-success.page.html',
  styleUrls: ['./booking-success.page.scss'],
})
export class BookingSuccessPage implements OnInit {

  constructor(private router: Router, private modalCrtl: ModalController) { }

  ngOnInit() {
  }

  goToHome() {
    this.modalCrtl.dismiss();
    this.router.navigate(['/tabs/home']);
  }

  goToAppointment() {
    this.modalCrtl.dismiss();
    this.router.navigate(['/tabs/appoinments']);
  }

}
