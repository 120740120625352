import { Router } from '@angular/router';
import {Component, OnInit} from '@angular/core';
import {NavController} from '@ionic/angular';


@Component({
    selector: 'app-package-detail',
    templateUrl: './package-detail.page.html',
    styleUrls: ['./package-detail.page.scss'],
})
export class PackageDetailPage implements OnInit {

    services = [
        'Hair Sytling',
        'Hair Coloring',
        'Corner Lashes',
        'Nail',
        'Make up',
        'Retock',
        'Body Glowimg',
        'Facial',
        'Eyebrows',
        'Spa'
    ]

    constructor(private navCtrl: NavController,private router: Router) {
    }

    ngOnInit() {
    }

    close() {
        this.navCtrl.back();
    }

    goToDateTime() {
        this.router.navigate(['date-time']);
    }

}
