import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';

import {HttpClient} from '@angular/common/http';

import {AutoCompleteService} from 'ionic4-auto-complete';

@Injectable()

export class CompleteTestService implements AutoCompleteService {
  labelAttribute = 'name';
  formValueAttribute = 'numericCode';

  departement;
	
	
  constructor(private http:HttpClient) {
  this.departement = localStorage.getItem('departement');
  }




  getResults(keyword:string) {
     if (!keyword) { return false; }
  this.departement = localStorage.getItem('departement');

     return this.http.get('https://accor.fidelitab.fr/appc/ajax_client_new.php?requete=listecommercants&search=' + keyword).pipe(map(
        (result: any[]) => {
			
           return result.filter(
              (item) => {
				 //console.log(item.name);
                 return item.name.toLowerCase();
              }
           );
			
        }
     ));
  }
	
  getItemLabel(country: any) {
    return country.name;// + ' (' + country.id + ')'
  }	
}