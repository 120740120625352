import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { HttpClientModule } from  '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AutoCompleteModule } from 'ionic4-auto-complete';
import { CompleteTestService } from './services/CompleteTestService';
import { AppComponent } from './app.component';

import { PackageDetailPageModule } from './pages/package-detail/package-detail.module';
import { BookingSuccessPageModule } from './pages/booking-success/booking-success.module';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
	  HttpClientModule,
    PackageDetailPageModule,
    BookingSuccessPageModule
  ],
  providers: [
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
	CompleteTestService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
