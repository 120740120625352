import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'welcome',
    loadChildren: () => import('./pages/welcome/welcome.module').then(m => m.WelcomePageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'reset',
    loadChildren: () => import('./pages/reset/reset.module').then(m => m.ResetPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'near-me',
    loadChildren: () => import('./pages/near-me/near-me.module').then(m => m.NearMePageModule)
  },
  {
    path: 'inbox',
    loadChildren: () => import('./pages/inbox/inbox.module').then(m => m.InboxPageModule)
  },
  {
    path: 'qrcode',
    loadChildren: () => import('./pages/qrcode/qrcode.module').then(m => m.QrcodePageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule)
  },
  {
    path: 'single',
    loadChildren: () => import('./pages/single/single.module').then(m => m.SinglePageModule)
  },
  {
    path: 'booking-info',
    loadChildren: () => import('./pages/booking-info/booking-info.module').then(m => m.BookingInfoPageModule)
  },
  {
    path: 'booking',
    loadChildren: () => import('./pages/booking/booking.module').then(m => m.BookingPageModule)
  },
  {
    path: 'new-card',
    loadChildren: () => import('./pages/new-card/new-card.module').then(m => m.NewCardPageModule)
  },
  {
    path: 'chat',
    loadChildren: () => import('./pages/chat/chat.module').then(m => m.ChatPageModule)
  },
  {
    path: 'categories',
    loadChildren: () => import('./pages/categories/categories.module').then(m => m.CategoriesPageModule)
  },
  {
    path: 'salons',
    loadChildren: () => import('./pages/salons/salons.module').then(m => m.SalonsPageModule)
  },
  {
    path: 'individuals',
    loadChildren: () => import('./pages/individuals/individuals.module').then(m => m.IndividualsPageModule)
  },
  {
    path: 'category',
    loadChildren: () => import('./pages/category/category.module').then(m => m.CategoryPageModule)
  },
  {
    path: 'package-detail',
    loadChildren: () => import('./pages/package-detail/package-detail.module').then(m => m.PackageDetailPageModule)
  },
  {
    path: 'barber-profile',
    loadChildren: () => import('./pages/barber-profile/barber-profile.module').then(m => m.BarberProfilePageModule)
  },
  {
    path: 'booking-success',
    loadChildren: () => import('./pages/booking-success/booking-success.module').then(m => m.BookingSuccessPageModule)
  },
  {
    path: 'favourite',
    loadChildren: () => import('./pages/favourite/favourite.module').then(m => m.FavouritePageModule)
  },
  {
    path: 'edit-profile',
    loadChildren: () => import('./pages/edit-profile/edit-profile.module').then(m => m.EditProfilePageModule)
  },
  {
    path: 'payment-method',
    loadChildren: () => import('./pages/payment-method/payment-method.module').then(m => m.PaymentMethodPageModule)
  },
  {
    path: 'payment-history',
    loadChildren: () => import('./pages/payment-history/payment-history.module').then(m => m.PaymentHistoryPageModule)
  },
  {
    path: 'aboutus',
    loadChildren: () => import('./pages/aboutus/aboutus.module').then(m => m.AboutusPageModule)
  },
  {
    path: 'date-time',
    loadChildren: () => import('./pages/date-time/date-time.module').then(m => m.DateTimePageModule)
  },
  {
    path: 'summary',
    loadChildren: () => import('./pages/summary/summary.module').then(m => m.SummaryPageModule)
  },
  {
    path: 'payments',
    loadChildren: () => import('./pages/payments/payments.module').then(m => m.PaymentsPageModule)
  },
  {
    path: 'home-services',
    loadChildren: () => import('./pages/home-services/home-services.module').then( m => m.HomeServicesPageModule)
  },
  {
    path: 'password',
    loadChildren: () => import('./pages/password/password.module').then( m => m.PasswordPageModule)
  },
  
  {
    path: 'partenaires',
    loadChildren: () => import('./pages/partenaires/partenaires.module').then( m => m.PartenairesPageModule)
  },
  {
    path: 'doc',
    loadChildren: () => import('./pages/doc/doc.module').then( m => m.DocPageModule)
  },
  {
    path: 'resultats',
    loadChildren: () => import('./pages/resultats/resultats.module').then( m => m.ResultatsPageModule)
  },
  {
    path: 'documentation-detail',
    loadChildren: () => import('./pages/documentation-detail/documentation-detail.module').then( m => m.DocumentationDetailPageModule)
  },
  {
    path: 'resultat-detail',
    loadChildren: () => import('./pages/resultat-detail/resultat-detail.module').then( m => m.ResultatDetailPageModule)
  },
  {
    path: 'activites',
    loadChildren: () => import('./pages/activites/activites.module').then( m => m.ActivitesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'activites-detail',
    loadChildren: () => import('./pages/activites-detail/activites-detail.module').then( m => m.ActivitesDetailPageModule)
  },
  {
    path: 'diplome-ok',
    loadChildren: () => import('./pages/diplome-ok/diplome-ok.module').then( m => m.DiplomeOkPageModule)
  },
  {
    path: 'suivi-tracking',
    loadChildren: () => import('./pages/suivi-tracking/suivi-tracking.module').then( m => m.SuiviTrackingPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'scan-arrivee',
    loadChildren: () => import('./pages/scan-arrivee/scan-arrivee.module').then( m => m.ScanArriveePageModule)
  },
  {
    path: 'arrivee',
    loadChildren: () => import('./pages/arrivee/arrivee.module').then( m => m.ArriveePageModule)
  },
  {
    path: 'detail-activite-arrivee',
    loadChildren: () => import('./pages/detail-activite-arrivee/detail-activite-arrivee.module').then( m => m.DetailActiviteArriveePageModule)
  },
  {
    path: 'resultat-recherche',
    loadChildren: () => import('./pages/resultat-recherche/resultat-recherche.module').then( m => m.ResultatRecherchePageModule)
  },
  {
    path: 'reclamation',
    loadChildren: () => import('./pages/reclamation/reclamation.module').then( m => m.ReclamationPageModule)
  },
  {
    path: 'plan',
    loadChildren: () => import('./pages/plan/plan.module').then( m => m.PlanPageModule)
  },
  {
    path: 'departement',
    loadChildren: () => import('./pages/departement/departement.module').then( m => m.DepartementPageModule)
  },
  {
    path: 'dep-plan',
    loadChildren: () => import('./pages/dep-plan/dep-plan.module').then( m => m.DepPlanPageModule)
  },
  {
    path: 'add-carte',
    loadChildren: () => import('./pages/add-carte/add-carte.module').then( m => m.AddCartePageModule)
  },
  {
    path: 'rechargement',
    loadChildren: () => import('./pages/rechargement/rechargement.module').then( m => m.RechargementPageModule)
  },
  {
    path: 'stripe',
    loadChildren: () => import('./pages/stripe/stripe.module').then( m => m.StripePageModule)
  },
 
  {
    path: 'historique-commande',
    loadChildren: () => import('./pages/historique-commande/historique-commande.module').then( m => m.HistoriqueCommandePageModule)
  },
  {
    path: 'demande-carte-physique',
    loadChildren: () => import('./pages/demande-carte-physique/demande-carte-physique.module').then( m => m.DemandeCartePhysiquePageModule)
  },
  {
    path: 'actualite',
    loadChildren: () => import('./pages/actualite/actualite.module').then( m => m.ActualitePageModule)
  },
  {
    path: 'create-profile',
    loadChildren: () => import('./pages/create-profile/create-profile.module').then( m => m.CreateProfilePageModule)
  },
  {
    path: 'jeu',
    loadChildren: () => import('./pages/jeu/jeu.module').then( m => m.JeuPageModule)
  },
  {
    path: 'jeu-validation',
    loadChildren: () => import('./pages/jeu-validation/jeu-validation.module').then( m => m.JeuValidationPageModule)
  },
  {
    path: 'jeu-fin',
    loadChildren: () => import('./pages/jeu-fin/jeu-fin.module').then( m => m.JeuFinPageModule)
  },








];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload', preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
